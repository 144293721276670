import FontFaceObserver from 'fontfaceobserver';

export default () => {
	const $html = document.getElementsByTagName('html')[0];
	const bold = new FontFaceObserver('Gotham-Rounded-Bold');
	const boldItalic = new FontFaceObserver('Gotham-Rounded-Bold-Italic');
	const book = new FontFaceObserver('Gotham-Rounded-Book');
	const bookItalic = new FontFaceObserver('Gotham-Rounded-Book-Italic');
	const medium = new FontFaceObserver('Gotham-Rounded-Medium');
	const mediumItalic = new FontFaceObserver('Gotham-Rounded-Medium-Italic');

	Promise.all([
		bold.load(),
		// boldItalic.load(),
		book.load(),
		bookItalic.load(),
		medium.load(),
		mediumItalic.load(),
	])
		.then(() => $html.classList.add('fonts-loaded'))
		.catch(() => $html.classList.add('fonts-failed'));
};
